import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ContactsActionTypes } from './contactTypes';
// import { AuthLoginActionTypes } from '../auth/authTypes';
import {
  contactsApiResponseSuccess,
  contactsApiResponseError,
} from './contactActions';
import {
  fetchContactsApi,
  updateContactTypeApi,
  setFollowUpConfigApi,
  getFollowUpConfigApi,
  getFollowupScheduleApi,
  updateTrackAbilityApi
} from '../../api/contacts';

import { showSuccessNotification, showErrorNotification } from "../../../src/helpers/NotificationContext"
import { authLoginApiResponseSuccess } from '../auth/authActions';
import { AuthLoginActionTypes } from '../auth/authTypes';

// Saga to handle fetching contacts
function* getContacts() {
  try {
    console.log("about to fetch contacts through api")
    const response: Promise<any> = yield call(fetchContactsApi);
    console.log("response from get contacts ", response)
    yield put(
      contactsApiResponseSuccess(ContactsActionTypes.GET_CONTACTS, response)
    );
  } catch (error: any) {
    yield put(contactsApiResponseError(ContactsActionTypes.GET_CONTACTS, error));
  }
}

// Saga to handle updating contact type
function* updateContactType({ payload: { id, contact_type } }: any) {
  try {
    console.log("about to call api ", id, contact_type)
    const response: Promise<any> = yield call(updateContactTypeApi, id, contact_type);
    console.log("post api call ", response)
    yield put(
      contactsApiResponseSuccess(ContactsActionTypes.UPDATE_CONTACT_TYPE, response)
    );
  } catch (error: any) {
    yield put(
      contactsApiResponseError(ContactsActionTypes.UPDATE_CONTACT_TYPE, error)
    );
  }
}

function* updateTrackAbility({ payload: { id } }: any) {
  try {
    console.log("track about to call api Trackablity", id)
    const response: Promise<any> = yield call(updateTrackAbilityApi, id);
    console.log("track post api call ", response)
    yield put(
      contactsApiResponseSuccess(ContactsActionTypes.UPDATE_CONTACT_TRACK_ABILITY, response)
    );

    yield put(
      authLoginApiResponseSuccess(AuthLoginActionTypes.UPDATE_CONTACT_TRACK_ABILITY, response)
    );

  } catch (error: any) {
    yield put(
      contactsApiResponseError(ContactsActionTypes.UPDATE_CONTACT_TRACK_ABILITY, error)
    );
  }
}

// Saga to handle setting the follow-up configuration
function* setFollowUpConfig({ payload: { configString } }: any) {
  try {
    const response: Promise<any> = yield call(setFollowUpConfigApi, configString);
    console.log("Set response from saga", response)
    yield put(
      contactsApiResponseSuccess(ContactsActionTypes.SET_FOLLOWUP_CONFIG, response)
    );
    yield call(showSuccessNotification, 'Follow-up configuration set successfully.');
  } catch (error: any) {
    yield put(
      contactsApiResponseError(ContactsActionTypes.SET_FOLLOWUP_CONFIG, error)
    )
    yield call(showErrorNotification, 'Failed to set follow-up configuration.');
  }
}

// Saga to handle fetching follow-up configuration
function* getFollowUpConfig() {
  try {
    const response: Promise<any> = yield call(getFollowUpConfigApi);

    yield put(
      contactsApiResponseSuccess(ContactsActionTypes.GET_FOLLOWUP_CONFIG, response)
    );
  } catch (error: any) {
    yield put(contactsApiResponseError(ContactsActionTypes.GET_FOLLOWUP_CONFIG, error));
  }
}


// Saga to handle fetching getFollowupSchedule
function* getFollowupSchedule() {
  try {
    const response: Promise<any> = yield call(getFollowupScheduleApi);

    yield put(
      contactsApiResponseSuccess(ContactsActionTypes.GET_FOLLOWUP_SCHEDULE, response)
    );
  } catch (error: any) {
    yield put(contactsApiResponseError(ContactsActionTypes.GET_FOLLOWUP_SCHEDULE, error));
  }
}


// Root saga to watch for the actions
export default function* contactsSaga() {
  yield takeLatest(ContactsActionTypes.GET_CONTACTS, getContacts);
  yield takeLatest(ContactsActionTypes.UPDATE_CONTACT_TYPE, updateContactType);
  yield takeLatest(ContactsActionTypes.UPDATE_CONTACT_TRACK_ABILITY, updateTrackAbility);
  yield takeLatest(ContactsActionTypes.SET_FOLLOWUP_CONFIG, setFollowUpConfig);
  yield takeLatest(ContactsActionTypes.GET_FOLLOWUP_CONFIG, getFollowUpConfig);  // New saga
  yield takeLatest(ContactsActionTypes.GET_FOLLOWUP_SCHEDULE, getFollowupSchedule);
}
