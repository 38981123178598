// contactsApi.ts
import { APINodeClient } from './apiNodeCore';
import * as nodeUrl from './nodeUrls';

const api = new APINodeClient();

// Fetch contacts from the backend
export const fetchContactsApi = async () => {
  return api.get(nodeUrl.GET_CONTACTS);
};

// Update contact type
export const updateContactTypeApi = async (id: string, contact_type: string) => {
  return api.create(`${nodeUrl.UPDATE_CONTACT_TYPE}`, { id, contact_type });
};

// Set follow-up configuration
export const setFollowUpConfigApi = async (config: any) => {
  return api.create(nodeUrl.SET_FOLLOWUP_CONFIG, config);
};

// Get follow-up configuration
export const getFollowUpConfigApi = async () => {
  return api.create(nodeUrl.GET_FOLLOWUP_CONFIG);
};

export const getFollowupScheduleApi = async () => {
  return api.get(nodeUrl.GET_FOLLOWUP_SCHEDULE);
};


export const updateTrackAbilityApi = async (id: string) => {
  return api.create(`${nodeUrl.UPDATE_CONTACT_TRACK_ABILITY}`, { id });
};
